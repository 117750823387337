
























import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineChem1LCLabReport1PurposeConcepts',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        purposeConceptsAnswer: null,
      },
      questions: [
        {
          text: 'In the space below, please write the concept(s) you were investigating and how they related to the guiding question(s).',
          inputName: 'purposeConceptsAnswer',
          maxInputLength: 3000,
        },
      ],
    };
  },
});
